<template>
  <div class="loader-container" v-if="isLoading">
    <ip-loader />
  </div>
  <div class="user__container user-reviews" v-else>
    <div class="user__body">
      <div class="wrapper__body__elem">
        <div class="wrapper__body__elem__title">
          Отзывы о пользователе
        </div>
        <div class="wrapper__body__elem__info">
          <div
            v-for="review in userReviews"
            :key="review.id"
            class="user-reviews__elem">
            <reviews-item
              :review="review"
              selectable
              :selected-reviews="selectedReviews"
              @selectReview="selectReview"/>
          </div>
          <div class="no-data" v-if="!userReviews.length">
            Нет отзывов
          </div>
        </div>
      </div>
      <div class="wrapper__body__elem">
        <div class="wrapper__body__elem__title">
          Отзывы, написанные пользователем
        </div>
        <div class="wrapper__body__elem__info">
          <div
            v-for="review in fromReviews"
            :key="review.id"
            class="user-reviews__elem">
            <reviews-item
              :review="review"
              selectable
              :selected-reviews="selectedReviews"
              @selectReview="selectReview"/>
          </div>
          <div class="no-data" v-if="!fromReviews.length">
            Нет отзывов
          </div>
        </div>
      </div>
    </div>
    <div class="user__footer">
      <ip-select
        :options="decisions"
        label="Решение"
        placeholder="Не выбрано"
        @onSelect="value => decision = value"
        :selected-item="decision"
        v-validate="{required: true}"
        v-model="decision"
        name="decision"
        :invalid="errors.has('decision')"
        open="top"
      />
      <ip-select
        :options="reasons"
        label="Резолюция"
        placeholder="Не выбрано"
        @onSelect="value => reason = value"
        :selected-item="reason"
        v-validate="{required: true}"
        v-model="reason"
        name="reason"
        :invalid="errors.has('reason')"
        open="top"
      />
      <ip-text-input
        name="comment"
        id="comment"
        v-model="comment"
        label="Комментарий"
        placeholder="Ввести..."
      />
      <ip-button
        theme="rounded"
        color="blue"
        @click="saveReviews"
        :disabled="!selectedReviews.length">
        Сохранить
      </ip-button>
    </div>
  </div>
</template>

<script>
import IpSelect from "../../components/IpSelect";
import IpTextInput from "../../components/IpTextInput";
import IpButton from "../../components/IpButton";
import IpLoader from "../../components/IpLoader";
import ReviewsItem from "../../components/ReviewsItem";
import apiRoutes from '../../consts/apiRoutes';

export default {
  name: 'user-reviews',

  components: {
    IpSelect,
    IpTextInput,
    IpButton,
    IpLoader,
    ReviewsItem
  },

  data() {
    return {
      isLoading: true,
      decisions: [
        {
          text: 'Не выбрано',
          value: ''
        },
        {
          text: 'Найдены нарушения',
          value: 'accepted'
        }
      ],
      reasons: [
        {
          text: 'Не выбрано',
          value: ''
        },
        {
          text: 'Нарушения не найдены',
          value: 'no_violation'
        },
        {
          text: 'Удалить отзыв',
          value: 'delete_review'
        },
        {
          text: 'Удалить отзыв и заблокировать юзера до выяснения',
          value: 'delete_review_temporary_ban'
        },
        {
          text: 'Удалить отзыв и бан юзера',
          value: 'delete_review_permanent_ban'
        }
      ],
      decision: '',
      reason: '',
      comment: '',
      selectedReviews: [],
      fromReviews: [],
      userReviews: []
    };
  },

  mounted() {
    this.getReviews();
  },

  methods: {

    async saveReviews() {
      const isValid = await this.$validator.validate();

      if (!isValid) {
        return;
      }

      this.isLoading = true;
      const data = {
        reviewIds: this.selectedReviews,
        decision: this.decision,
        reason: this.reason,
        comment: this.comment
      };

      try {
        await this.axios.post(apiRoutes.updateClientReviews, data);
        this.isLoading = false;
        this.selectedReviews = [];
        this.getReviews();
      } catch(error) {
        console.warn(error);
        this.isLoading = false;
      }
    },

    selectReview(id) {
      if (this.selectedReviews.includes(id)) {
        this.selectedReviews = this.selectedReviews.filter(el => el !== id);
      } else {
        this.selectedReviews.push(id);
      }
    },

    async getReviews() {
      this.isLoading = true;
      const response = await this.axios.get(apiRoutes.clientReviews.replace('{id}', this.$route.params.id));
      this.fromReviews = response.data.fromReviews;
      this.userReviews = response.data.userReviews;
      this.isLoading = false;
    }
  }
}
</script>

<style scoped lang="scss">
.user__body{
  flex: 1 1 auto;
  height: calc(100% - 92px);
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}
.user-reviews__elem{
  padding-bottom: 24px;
  border-bottom: 1px solid $alto;
  margin-bottom: 24px;
  &:last-child{
    border: none;
  }
}
</style>