<template>
  <div class="star-rating">
    <inline-svg
      v-for="el in star"
      :key="`${el}-filled`"
      :src="require('../assets/icons/star_24px_outlined.svg')"
    ></inline-svg>
    <inline-svg
      v-for="point in (max - star)"
      :key="point"
      :src="require('../assets/icons/star_border_24px_outlined.svg')"
    ></inline-svg>
  </div>
</template>

<script>
export default {
  props: {
    max: {
      type: Number,
      required: true
    },

    star: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped lang="scss">
  .star-rating{
    display: flex;
    align-items: center;
    &.clicable{
      ::v-deep svg{
        cursor: pointer;
      }
    }
  }
</style>