<template>
  <div class="avatar" :style="style">
    <inline-svg
       v-if="!user.details || !user.details.photoUrl"
      :src="require('../assets/icons/perm_identity_24px.svg')"
    ></inline-svg>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },

  computed: {
    style() {
      if (!this.user.details || !this.user.details.photoUrl) {
        return {};
      }

      return {
        'background-image': `url(${this.user.details.photoUrl})`
      };
    },

    firstLetter() {
      return this.user.details && this.user.details.firstName && this.user.details.firstName.slice(0, 1).toUpperCase();
    }
  }

}
</script>

<style scoped lang="scss">
.avatar{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $white;
  color: $black;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
</style>