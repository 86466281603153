<template>
  <div class="reviews-item">
    <div class="info">
      <ip-avatar :user="review.from" />
      <div class="name" v-if="review.from">
        <p>
          {{ review.from.details.firstName }}
        </p>
        <p>
          {{ review.from.details.lastName }}
        </p>
      </div>
      <inline-svg
        v-if="review.isVerified"
        fill="#00A084"
        :src="require('../assets/icons/assignment_turned_in_24px.svg')"
      ></inline-svg>
      <inline-svg
        v-else
        fill="#EB5757"
        :src="require('../assets/icons/assignment_late_24px.svg')"
      ></inline-svg>
      <div class="rating">
        <ip-star-rating :max="10" :star="review.rating"/>
      </div>
      <div class="actions" v-if="selectable">
        <div
          class="review-select"
          :class="{'selected': isSelected}"
          @click="$emit('selectReview', review.id)">
          <inline-svg
            v-if="isSelected"
            :src="require('../assets/icons/check_24px_outlined.svg')" />
        </div>
      </div>
    </div>
    <div class="message" v-if="review.text">
      {{ review.text }}
    </div>
  </div>
</template>

<script>
import IpStarRating from "../components/IpStarRating";
import IpAvatar from '../components/IpAvatar';

export default {
  name: 'reviews-item',

  components: {
    IpStarRating,
    IpAvatar
  },

  props: {
    review: {
      type: Object,
      required: true
    },

    selectable: {
      type: Boolean,
      default: false
    },

    selectedReviews: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    isSelected() {
      return this.selectedReviews.includes(this.review.id);
    }
  }
}
</script>

<style scoped lang="scss">
  .reviews-item{
    .info{
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      ::v-deep .avatar{
        margin-right: 8px;
        flex: none;
        width: 50px;
        height: 50px;
      }
      .name{
        width: 85px;
        overflow: hidden;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: $black;
        p{
          margin: 0;
          padding: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      & > ::v-deep svg{
        margin-right: 12px;
        flex: none;
      }
      .rating{
        padding-left: 12px;
        border-left: solid 1px $athensGray;
        height: 50px;
        display: flex;
        align-items: center;
      }
    }
    .message{
      background: $athensGray;
      border-radius: 12px;
      padding: 15px 24px;
      font-size: 14px;
    }
    .actions{
      flex: 1 1 auto;
      display: flex;
      justify-content: flex-end;
    }
    .review-select{
      margin-left: 8px;
      background: $white;
      border: 1px solid $silver;
      box-sizing: border-box;
      border-radius: 12px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: none;
      margin-right: 14px;
      &:hover{
        cursor: pointer;
      }
      &.selected{
        background: $scienceBlue;
      }
    }
  }
</style>